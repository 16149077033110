import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    {
      style: {
        marginTop: "-50px",
        height: "100vh",
        width: "100vw",
        backgroundImage: "url(" + require("@/assets/bg-home-up-bvs.png") + ")",
        backgroundRepeat: "round"
      },
      attrs: { fluid: "", "fill-height": "", id: "principal" }
    },
    [
      _c(
        VCol,
        {
          staticClass: "ma-auto",
          attrs: { cols: "10", sm: "10", md: "10", lg: "4" }
        },
        [
          _c(
            VCard,
            {
              staticClass: "mt-10",
              attrs: { color: "rgb(255, 255, 255, 0.95)" }
            },
            [
              _c(
                VCol,
                {
                  staticClass: "ma-auto pt-10",
                  attrs: { cols: "8", sm: "4", md: "4", lg: "8" }
                },
                [
                  _c(VImg, {
                    attrs: {
                      "align-center": "",
                      "justify-center": "",
                      src: _vm.logo,
                      alt: "logo-recuperi"
                    }
                  })
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "ma-auto", attrs: { cols: "12", lg: "10" } },
                [
                  _c(
                    VCardText,
                    [
                      _c(
                        VForm,
                        {
                          ref: "form",
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.checkformSubmit()
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(VTextField, {
                            attrs: {
                              "prepend-inner-icon": "person",
                              name: "email",
                              label: "Digite seu e-mail",
                              type: "email",
                              rules: _vm.emailRules,
                              required: "",
                              small: "",
                              solo: "",
                              "data-cy": "signinEmailField"
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _c(VTextField, {
                            attrs: {
                              "prepend-inner-icon": "lock",
                              name: "password",
                              label: "Digite sua senha",
                              type: _vm.show1 ? "text" : "password",
                              "append-icon": _vm.show1
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              "data-cy": "signinPasswordField",
                              rules: _vm.passwordRules,
                              small: "",
                              solo: "",
                              required: ""
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.show1 = !_vm.show1
                              }
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          _c(
                            VBtn,
                            {
                              staticClass: "grey--text mt-4",
                              attrs: { text: "", "x-small": "", dense: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialogRecuperar = !_vm.dialogRecuperar
                                }
                              }
                            },
                            [_vm._v("Esqueceu a senha?")]
                          ),
                          _c(VCheckbox, {
                            attrs: { "x-small": "", required: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(
                                          " Declaro estar de acordo com os "
                                        ),
                                        _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            target: "_blank",
                                                            href:
                                                              "https://recuperi.com.br/institucional/termos-de-uso"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Termos de Uso "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [_vm._v(" Termos de uso ")]
                                        ),
                                        _vm._v(" , "),
                                        _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            target: "_blank",
                                                            href:
                                                              "https://recuperi.com.br/institucional/politica-de-privacidade"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Política de Privacidade "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [_vm._v(" Política de Privacidade ")]
                                        ),
                                        _vm._v(" e "),
                                        _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            target: "_blank",
                                                            href:
                                                              "https://recuperi.com.br/institucional/codigo-de-etica"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Código de Ética "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [_vm._v(" Código de Ética ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.checkboxTermos,
                              callback: function($$v) {
                                _vm.checkboxTermos = $$v
                              },
                              expression: "checkboxTermos"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: _vm.primaryColorFont,
                            disabled: _vm.checkForm(),
                            block: "",
                            "data-cy": "signinSubmitBtn",
                            loading: _vm.load_login
                          },
                          on: { click: _vm.submit }
                        },
                        [
                          _vm._v(" Entrar "),
                          _c(VIcon, { staticClass: "ml-1" }, [
                            _vm._v("chevron_right")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.dialogRecuperar,
            callback: function($$v) {
              _vm.dialogRecuperar = $$v
            },
            expression: "dialogRecuperar"
          }
        },
        [
          _c(
            VCard,
            [
              this.email == "" || !/.+@.+/.test(this.email)
                ? _c(VCardText, { staticClass: "pt-5 mb-n10" }, [
                    _vm._v("Informe um e-mail válido")
                  ])
                : _c(VCardText, { staticClass: "pt-5 mb-n10" }, [
                    _vm._v("Um e-mail será enviado para " + _vm._s(_vm.email))
                  ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  this.email != "" && /.+@.+/.test(this.email)
                    ? _c(
                        VBtn,
                        {
                          attrs: { color: "green darken-1", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.enviaEmail(_vm.email)
                            }
                          }
                        },
                        [_vm._v("Enviar")]
                      )
                    : _vm._e(),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogRecuperar = !_vm.dialogRecuperar
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: "red white--text",
              mensagemSnackbar: "E-mail ou senha incorreto."
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }